/**
 * Makes a GET request to the api at a supplied url.
 * It can determine if the browser is authenticated or not.
 * Some API calls don't require authentication.
 */

import { useEffect, useState, useContext } from 'react';
import { AppStateContext } from "../contexts/AppStateContext"
import axios from 'axios'
import settings from "../lib/settings"


function useFetch(url) {
  const appState = useContext(AppStateContext);
  console.log("url", url)
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  let axiosInstance = null;

  const initAxiosInstance = () => {
    if (!axiosInstance) {
      axiosInstance = axios.create({
        baseURL: settings.get('GATSBY_DEBIE_API_BASE_URI'),
        timeout: 10000,
        // headers: {'X-Custom-Header': 'foobar'}
      });
    }
  }

  async function fetchGet(url) {
    initAxiosInstance();
    if(appState.profileUuid.value){
      const data = {
        profile_uuid: appState.profileUuid.value,
      }
      axiosInstance.post(url, data)
        .then(function (response) {
          if (response.status >= 400) {
            setData(404);
          }
          else {
            // const json = await response.json();
            setData(response.data);
            // console.log(json)
          }

          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          // handle error
          setData(404);
        })
    }else{
      setData(404);
    }
  }

  useEffect(() => {
    fetchGet(url);
  }, []);
  return [data, loading];
}

export { useFetch };
