import React, { useContext, useState, useEffect } from "react"
import $ from 'jquery'
import _ from 'lodash'
import DatePicker from 'react-datetime'
import axios from 'axios'
import NumberFormat from 'react-number-format';
import Layout from "../components/layout"
import { navigate } from "gatsby"
import { AppStateContext } from "../contexts/AppStateContext"
import analytics from "../lib/analytics"
import LocationSearchInput from "../components/LocationSearchInput"
import settings from "../lib/settings"
import Loader from "../components/Loader"
import { useFetch } from '../hooks/useFetch'
import moment from 'moment'

let axiosInstance = null;
let choices = {'basic': 'Business Basic', 'bump': 'Business Bump', 'boost': 'Business Boost', 'bump_yearly': 'Business Bump', 'boost_yearly': 'Business Boost'}
//TODO(Selberg): better place for library init calls
analytics.init()

const AccountPage = () => {
  let [data, loading] = useFetch('/get-account-settings/');
  const appState = useContext(AppStateContext);

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState();
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState();
  const [legalBusinessName, setLegalBusinessName] = useState("");
  const [legalBusinessNameError, setLegalBusinessNameError] = useState("");
  const [tradeName, setTradeName] = useState("");
  const [tradeNameError, setTradeNameError] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessEmailError, setBusinessEmailError] = useState();
  const [address1, setAddress1] = useState("");
  const [address1Error, setAddress1Error] = useState("");
  const [pincode, setPincode] = useState("");
  const [pincodeError, setPincodeError] = useState();
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState();
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState();
  const [einNumber, setEinNumber] = useState("");
  const [businessEinError, setBusinessEinError] = useState();
  const [startDate, setStartDate] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [leaseAmount, setLeaseAmount] = useState("");
  const [leaseAmountError, setLeaseAmountError] = useState("");
  const [ownerName, setOwnerName] = useState("")
  const [ownerNameError, setOwnerNameError] = useState("")
  const [ownerMobile, setOwnerMobile] = useState("")
  const [ownerMobileError, setownerMobileError] = useState("")
  const [unitNumber, setUnitNumber] = useState("")
  const [unitNumberError, setUnitNumberError] = useState("")
  // const [bankName, setBankName] = useState(false);
  // const [passwordError, setPasswordError] = useState();
  // const [selectedFormIndex, setSelectedFormIndex] = useState(0);
  const [account, setAccount] = useState(null)
  const [reconnect, setReconnect] = useState(false)
  const [addAccount, setAddAccount] = useState(false)
  const [updatePayment, setUpdatePayment] = useState(false)

  useEffect(() => {
    if(appState.profileUuid.value){
      initData(data)
      appState.redirect.set('/dashboard')
    }else{
      appState.redirect.set('/account')
      navigate('/login')
    }

  }, [loading]);

  useEffect(()=> {
    if(appState.sessionId.value !== ""){
      const flag = window.location.href.includes(appState.sessionId.value)
      if (flag){
        subscriptionUpdateRequest(flag)
      }
    }
  }, [])

  const initData = (data) => {
    // console.log(data.account)
    setFirstName(data.first_name)
    setLastName(data.last_name)
    setBusinessEmail(data.email)
    setLegalBusinessName(data.business_name)
    setAddress1(data.business_address)
    setPincode(data.zip_code)
    setEinNumber(data.business_ein)
    setOwnerName(data.owner_name)
    setOwnerMobile(data.owner_mobile)
    setUnitNumber(data.unit_number)
    appState.plan.set(data.plan)
    setAccount(data.account)
    if('lease_start_date' in data) {
      setStartDate(moment(data.lease_start_date).format('MM/DD/YY'))
      setLeaseAmount(data.monthly_lease_amount)
    }
    if('city' in data){
      setCity(data.city)
    }
    if('state' in data){
      setState(data.state)
    }
    if('reconnect' in data){
      setReconnect(data.reconnect)
    }
    if('add_account' in data){
      setAddAccount(data.add_account)
    }
    if ('reconnect' in data && data.reconnect){
      appState.account.set(data.account)
    }

    if ('add_account' in data && data.add_account){
      appState.account.set(data.account + '_add')
    }
    if ('update_payment' in data && data.update_payment){
      setUpdatePayment(data.update_payment)
    }
    // console.log("API", data.reconnect, data.add_account)
  }

  var fetchStripe = function(url = '/customer-portal') {
  const baseUrl = settings.get('GATSBY_DEBIE_API_BASE_URI')
  console.log("URL", baseUrl+url)
  return fetch(baseUrl + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        profile_uuid: appState.profileUuid.value
      })
    }).then(function(result) {
      // console.log('Fetch', result)
      return result.json()

    });
  };

  const manageStripe = function(url = '/customer-portal') {
    fetchStripe(url).then(function(result){
      // console.log('MANAGE', result)
      if('url' in result){
        // const myWindow = window.open(result.url, "Manage", "height=50vh", "width=50vh");
        window.location.href = result.url
      }
    });
  }

  const updateStripe = function(url) {
    const stripe = (window && window != undefined) ? window.Stripe(settings.get('GATSBY_STRIPE_PUBLISHABLE_KEY')): null;
    // console.log(priceId)
    fetchStripe(url).then(function(result) {
      // Call Stripe.js method to redirect to the new Checkout page
      // localStorage.setItem('plan', plan)
      // localStorage.setItem('sessionId',data.sessionId)
      // localStorage.setItem('profileUuid',appState.profileUuid.value)
      appState.sessionId.set(result.sessionId)
      stripe.redirectToCheckout({
          sessionId: result.sessionId
        })
        .then(function(out){
          // console.log("UPS", JSON.stringify(out), window.location.hrf)
          // appState.paymentPage.set(false);
        });
    });
  }

  const subscriptionUpdateRequest = (flag) => {
    // console.log(window.location.href)
    initAxiosInstance();
    const data = {
      profile_uuid: appState.profileUuid.value,
      session_id: appState.sessionId.value,
      success: flag
    }
    axiosInstance.post('/update-subscription/', data)
      .then(function (response) {
        // handle success
        // console.log('SUR', JSON.stringify(response), appState.plan.value)
        if('updated' in response.data){
          setUpdatePayment(!response.data.updated)
        }

      })
      .catch(function (error) {
        console.log(error);
        // handle error
        if (error.response && error.response.status && error.response.status === 400) {
          // displayValidationErrors(error.response.data);
        }
      })
  }

  const upgrade = () => {
    appState.paymentPage.set(true)
    appState.loggedIn.set(false)
    navigate('/dashboard')
  }

  const initAxiosInstance = () => {
    if (!axiosInstance) {
      axiosInstance = axios.create({
        baseURL: settings.get('GATSBY_DEBIE_API_BASE_URI'),
        timeout: 10000,
        // headers: {'X-Custom-Header': 'foobar'}
      });
    }
  }

  const getOnboardOneErrors = () => {
    let errors = {};
    let foundError = false;
    // Check rquired Fields
    if (firstName.trim() === "") {
      foundError = true;
      errors.first_name = "First name is required";
    }
    if (lastName.trim() === "") {
      foundError = true;
      errors.last_name = "Last name is required";
    }
    if (businessEmail.trim() === "") {
      foundError = true;
      errors.email = "Email is required";
    }
    if (legalBusinessName.trim() === "") {
      foundError = true;
      errors.legal_business_name = "Legal business name is required";
    }
    // if (tradeName.trim() === "") {
    //   foundError = true;
    //   errors.trade_name = "Trade name is required";
    // }
    if (foundError) {
      return errors;
    } else {
      return null;
    }
  }

  const getOnboardTwoErrors = () => {
    let errors = {};
    let foundError = false;
    // Check rquired Fields
    if (address1 != null && address1.trim() === "") {
      foundError = true;
      errors.business_address = "Business address is required";
    }
    if (pincode != null && pincode.trim() === "") {
      foundError = true;
      errors.zip_code = "Zip code is required";
    }
    // if (city.trim() === "") {
    //   foundError = true;
    //   errors.city = "City is required";
    // }
    // if (state.trim() === "") {
    //   foundError = true;
    //   errors.state = "State is required";
    // }
    if (foundError) {
      return errors;
    } else {
      return null;
    }
  }

  // const getOnboardThreeErrors = () => {
  //   let errors = {};
  //   let foundError = false;
  //   // Check rquired Fields
  //   if (!startDate) {
  //     foundError = true;
  //     errors.lease_start_date = "Lease start date is required";
  //   }
  //   if (leaseAmount.trim() === "") {
  //     foundError = true;
  //     errors.lease_amount = "Lease Amount is required";
  //   }
  //   if (foundError) {
  //     return errors;
  //   } else {
  //     return null;
  //   }
  // }


  const updateRequest = () => {

    const onboardOneErrors = getOnboardOneErrors();
    const onboardTwoErrors = getOnboardTwoErrors();
    // const onboardThreeErrors = getOnboardThreeErrors();
    let onboardErrors = {...onboardOneErrors, ...onboardTwoErrors}

    if(onboardOneErrors||onboardTwoErrors) {
      displayValidationErrors(onboardErrors)
      return
    }

    initAxiosInstance();
    const data = {
      profile_uuid: appState.profileUuid.value,
      first_name: firstName,
      last_name: lastName,
      legal_business_name: legalBusinessName,

    }
    if (leaseAmount){
      data['lease_amount'] = leaseAmount
    }
    if (startDate !== "" || startDate != null) {
      data['lease_start_date'] = moment(startDate).format('YYYY-MM-DD')
    }
    if(ownerName){
      data['owner_name'] = ownerName
    }
    if (ownerMobile){
      data['owner_mobile'] = ownerMobile
    }
    if (address1){
      data['business_address'] = address1
    }
    if (pincode){
      data['zip_code'] = pincode
    }
    if (city){
      data['city'] = city
    }
    if (state){
      data['state'] = state
    }
    if (einNumber){
      data['business_ein'] = einNumber
    }
    if(unitNumber){
      data['unit_number'] = unitNumber
    }


    axiosInstance.put("/get-account-settings/", data)
      .then(function (response) {
        // handle success
        if (response.data) {
          clearValidatonErrors()
          // console.log('UPDATED', data)
          navigate('/dashboard')
        } else {
          console.error("Invalid Response Received");
        }

      })
      .catch(function (error) {
        console.log(error);
        // handle error
        if (error.response && error.response.status && error.response.status === 400) {
          displayValidationErrors(error.response.data);
        }
        // switchToFormTab(2);
      })
  }

  const displayValidationErrors = (errorsObject) => {
    const { first_name, last_name, legal_business_name, /*trade_name, email, phone,password,*/ business_address, zip_code, city, state, lease_start_date, lease_amount,  business_ein } = errorsObject;
    setFirstNameError(first_name);
    setLastNameError(last_name);
    setLegalBusinessNameError(legal_business_name);
    // setTradeNameError(trade_name);
    // setBusinessEmailError(email);
    // setMobileError(phone);
    setAddress1Error(business_address);
    setPincodeError(zip_code);
    setStartDateError(lease_start_date);
    setLeaseAmountError(lease_amount);
    // setPasswordError(password);
    setBusinessEinError(business_ein);
    setCityError(city);
    setStateError(state);
  }

  const clearValidatonErrors = () => {
    displayValidationErrors({});
  }

  const switchToFormTab = (tabNumber) => {
    // if(tabNumber-1 !== selectedFormIndex){
      $(`#myTab li:nth-child(${tabNumber}) a`).tab('show');
      // setSelectedFormIndex(tabNumber - 1);
      // window.scrollTo(0, 0);
    // }
  }

  if (loading) {
    return(<Loader/>)
  }

  const clickRequest = () => {}

  switchToFormTab(2)
  // switchToFormTab(2)
  // switchToFormTab(3)
  // switchToFormTab(4)


  // console.log("ACCOUNT", appState)
  // console.log("DATA", data)

  return (
    <Layout showLoginButton={false} showLogoutButton={true}>
      <main className="container">
        <div className="debieBusinessInfo">
          <div className="row">
            <div className="col-sm-12">
              <div className="formWrap">
                <div className="tab-account" id="myTabContent">
                <div className="tab-pane fade show active" id="identity" role="tabpanel" aria-labelledby="identity-tab">
                  <div className="row">
                    <div className="col-sm-8">
                      <div className="settingsHeader">
                      <h1>Account Settings</h1>
                      <p>View your basic account and user settings.</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      {/*<div className="headingMd"><h3>Your Plan and Data</h3></div>*/}
                      <form className="formWrap" method="POST" onSubmit={(e) => { e.preventDefault(); clickRequest(); }}>
                        <div className="row">
                          <div className="col-sm-4">
                            {/*<div className="headingMd"><div className="InfoBankHeading"><span className='form-label'>Plan Settings</span></div></div>*/}
                            <div className="accountMessage infoBank">
                            <div className="row">
                                <div className="col-sm-6">
                                  <p><b>Your Plan:</b></p>
                                  <p><b>Reporting:</b></p>
                                </div>
                                <div className="col-sm-6">
                                  <p>{choices[appState.plan.value]}</p>
                                  <p>{appState.plan.value === 'basic' ? 'Quarterly': 'Monthly'}</p>
                                </div>
                              </div>
                              <div className='text-center'>
                              {appState.plan.value === 'bump'  ?
                                <button className="btn btn-100" onClick = {() => manageStripe()}>Upgrade</button> :
                                (appState.plan.value === 'basic' ? <button className="btn btn-100" onClick = {() => upgrade()}>Upgrade</button> :
                                <div style={{height:'71px'}}></div>
                                )
                              }
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            {/*<div className="headingMd"><div className="InfoBankHeading"><span className="form-label">Connected Accounts</span></div></div>*/}
                            <div className="accountMessage infoBank">

                            <div className="row">
                                <div className="col-sm-6">
                                  <p><b>Bank:</b></p>
                                  <p><b>Quickbooks:</b></p>
                                </div>
                                <div className="col-sm-6">
                                  <p>{account && account.includes('plaid') ? 'Connected': 'Not Connected'}</p>
                                  <p>{account && account.includes('quickbooks') ? 'Connected': 'Not Connected'}</p>
                                </div>
                              </div>
                              <div className='text-center'>
                              {addAccount  ?
                                <button className="btn btn-100" onClick = {() => navigate('/auth')}>{true ? 'Add' : 'Reconnect'}</button> :
                                <div style={{height:'71px'}}></div>
                              }
                              </div>
                               {/*<div style={{height:'11px'}}>
                                 <label className="form-label" htmlFor="bank-name">Bank Name
                                 <span className="required">*</span>
                                   <span className="form-control plaid-link">
                                      {
                                        bankName && appState.institution.value && appState.institution.value.logo &&
                                        <img src={`data:image/png;base64, ${appState.institution.value.logo}`} alt="" />
                                      }
                                      {bankName && <span>{bankName ? bankName : 'Connect a Bank Account'}</span>}
                                      </span>
                                  </label>
                                </div>*/}

                            </div>
                          </div>
                          {updatePayment && <div className="col-sm-4">
                            {/*<div className="headingMd"><div className="InfoBankHeading"><span className="form-label">Connected Accounts</span></div></div>*/}
                            <div className="accountMessage infoBank">

                            <div className="row">
                                <div className="col-sm-6">
                                  <p><b>Payment:</b></p>
                                  {<p><b>Last Payment:</b></p>}
                                </div>
                                <div className="col-sm-6">
                                  <p>Card</p>
                                  {<p>{updatePayment ? 'Pending':'Success'}</p>}
                                </div>
                              </div>
                              <div className='text-center'>
                              {updatePayment  ?
                                <button className="btn btn-100" onClick = {() => updateStripe('/update-checkout-session')}>{'Update Payment'}</button> :
                                <div style={{height:'71px'}}></div>
                              }
                              </div>
                            </div>
                          </div>}
                          <div className="col-sm-12">
                            <div className="form-group">

                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    {/*<div className="col-sm-4 text-center">
                        <p> 123</p>
                    </div>*/}
                  </div>
                </div>
                  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div className="row">
                      <div className="col-sm-8">
                        <div className="headingMd"><img src="/images/UserInformation.svg"></img><h3>User Information</h3></div>
                        <form className="formWrap" method="POST" onSubmit={(e) => { e.preventDefault(); clickRequest(); }} >
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                  <label className="form-label" htmlFor="first-name">First Name <span className="required">*</span>
                                  <input type="text" className="form-control" name="first-name" value={firstName} onChange={(e) => { setFirstName(e.target.value) }} />
                                  {
                                    firstNameError &&
                                    <span className="error-message">{firstNameError}</span>
                                  }
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                  <label className="form-label" htmlFor="last-name"  >Last Name <span className="required">*</span>
                                  <input type="text" className="form-control" name="last-name" value={lastName} onChange={(e) => { setLastName(e.target.value) }} />
                                  {
                                    lastNameError &&
                                    <span className="error-message">{lastNameError}</span>
                                  }</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                  <label className="form-label" htmlFor="legal-b-name">Legal Business Name <span className="required">*</span>
                                  <input type="text" className="form-control" name="legal-b-name" value={legalBusinessName} onChange={(e) => { setLegalBusinessName(e.target.value) }} />
                                  {
                                    legalBusinessNameError &&
                                    <span className="error-message">{legalBusinessNameError}</span>
                                  }
                                  </label>
                                </div>
                              </div>
                            </div>
                            {tradeName && <div className="col-sm-6">
                              <div className="form-group">
                                {/* <div>
                                  <label className="form-label" htmlFor="b-phone">Business Phone <span className="msg">(Optional)</span>
                                  <input readonly="readonly" type="number" name="b-phone" className="form-control" value={mobile} onChange={(e) => { setMobile(e.target.value) }} />
                                  {
                                    mobileError &&
                                    <span className="error-message">{mobileError}</span>
                                  }</label>
                                </div> */}
                                <div>
                                  <label className="form-label" htmlFor="trade-name">Trade Name <span className="required">*</span>
                                  <input readonly="readonly" readonly="readonly" type="text" name="trade-name" className="form-control" value={tradeName} onChange={(e) => { setTradeName(e.target.value) }} />
                                  {
                                    tradeNameError &&
                                    <span className="error-message">{tradeNameError}</span>
                                  }</label>
                                </div>
                              </div>
                            </div>}
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                  <label className="form-label" htmlFor="email">Email <span className="required">*</span>
                                  <input readonly="readonly" readonly="readonly" type="email" name="email" className="form-control" value={businessEmail} onChange={(e) => { setBusinessEmail(e.target.value) }} />
                                  {
                                    businessEmailError &&
                                    <span className="error-message">{businessEmailError}</span>
                                  }</label>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="">
                                <div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-sm-4 text-center">
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <div className="row">
                      <div className="col-sm-8">
                        <div className="headingMd"><img src="/images/BusinessInformation.svg"></img><h3>Business Information</h3></div>
                        <form className="formWrap" method="POST" onSubmit={(e) => { e.preventDefault(); clickRequest(); }}>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                  <label className="form-label" htmlFor="business-address">Business Address <span className="required">*</span>
                                  {<LocationSearchInput
                                    value={address1}
                                    onSelect={(address) => (
                                      setAddress1(address)
                                    )}
                                    onChange={(address) => (
                                      setAddress1(address)
                                    )}
                                    onReceiveStreet= {(street)=>{setAddress1(street)}}
                                    onReceivePostalCode={(postalCode)=>{setPincode(postalCode)}}
                                    onReceiveCity={(city)=>{setCity(city)}}
                                    onReceiveState={(state)=>{setState(state)}}
                                    inputClassName="form-control"
                                  />}
                                  {/*<input readonly="readonly" type="text" className="form-control" name="business-address" value={address1} onChange={(e) => { setAddress1(e.target.value) }} />*/}
                                  {
                                    address1Error &&
                                    <span className="error-message">{address1Error}</span>
                                  }</label>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                  <label className="form-label" htmlFor="zip-code">Zip Code <span className="required">*</span>
                                  <input type="text" className="form-control" name="zip-code" value={pincode} onChange={(e) => { setPincode(e.target.value) }} />
                                  {
                                    pincodeError &&
                                    <span className="error-message">{pincodeError}</span>
                                  }</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                  <label className="form-label" htmlFor="city">City <span className="required">*</span>
                                  <input type="text" className="form-control" name="city" value={city} onChange={(e) => { setCity(e.target.value) }} />
                                  {
                                    cityError &&
                                    <span className="error-message">{cityError}</span>
                                  }</label>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                  <label className="form-label" htmlFor="state">State <span className="required">*</span>
                                  <input type="text" className="form-control" name="state" value={state} onChange={(e) => { setState(e.target.value) }} />
                                  {
                                    stateError &&
                                    <span className="error-message">{stateError}</span>
                                  }</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                  <label className="form-label" htmlFor="b-ein">Unit/Suite #</label>
                                  <NumberFormat name="b-ein" className="form-control" mask="_" value={unitNumber} onValueChange={(e) => { setUnitNumber(e.value) }} />
                                  {
                                    unitNumberError &&
                                    <span className="error-message">{unitNumberError}</span>
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                  <label className="form-label" htmlFor="b-ein">Business EIN</label>
                                  <NumberFormat name="b-ein" className="form-control" format="##-#######" placeholder="XX-XXXXXXX" mask="_" value={einNumber} onValueChange={(e) => { setEinNumber(e.value) }} />
                                  {
                                    businessEinError &&
                                    <span className="error-message">{businessEinError}</span>
                                  }

                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {
                            <div>
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="headingMd"><img src="/images/LeasingInformation.svg"></img><h3>Lease Information</h3></div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <div>
                                      <label className="form-label" htmlFor="lease-start-date">Lease Start Date
                                        <div className="input-group date">
                                          {/*<input readonly="readonly" name="monthly-lease-amount" className="form-control"  value={startDate} />*/}
                                          {<DatePicker readonly="readonly" name="lease-start-date" viewMode="years" dateFormat="MM/DD/YYYY" timeFormat={false} closeOnSelect={true} value={startDate} onChange={(e) => { setStartDate(e) }} />}
                                          <span className="input-group-addon">
                                            <img src="images/calendar.png" alt="" />
                                          </span>
                                          {
                                            startDateError &&
                                            <span className="error-message">{startDateError}</span>
                                          }
                                        </div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <div>
                                      <label className="form-label" htmlFor="monthly-lease-amount">Monthly Lease Amount
                                      <input type="number" name="monthly-lease-amount" className="form-control" value={leaseAmount} onChange={(e) => { setLeaseAmount(e.target.value) }} />
                                      {
                                        leaseAmountError &&
                                        <span className="error-message">{leaseAmountError}</span>
                                      }</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <div>
                                      <label className="form-label" htmlFor="lease-owner-name">Property Manager Name
                                        <input type='text' name="lease-owner-name" className="form-control" value={ownerName} onChange={(e) => { setOwnerName(e.target.value) }} />
                                        {
                                          ownerNameError &&
                                          <span className="error-message">{ownerNameError}</span>
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <div>
                                      <label className="form-label" htmlFor="lease-owner-mobile">Property Manager Phone
                                      <NumberFormat
                                        name="lease-owner-mobile"
                                        className="form-control"
                                        format="(###) ###-####"
                                        placeholder="(XXX) XXX-XXXX"
                                        mask="_"
                                        value={ownerMobile}
                                        onValueChange={(e) => { setOwnerMobile(e.value) }}
                                        />
                                      {
                                        ownerMobileError &&
                                        <span className="error-message">{ownerMobileError}</span>
                                      }</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                          <div className="row">
                          <div className="col-sm-6" style={{display: 'flex', paddingVertical: '15px', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                            <p onClick = {() => manageStripe()}>Downgrade</p>
                          </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <div className="d-flex flex-row-reverse">
                                  <div>
                                    <button type="submit" disabled={false} className="btn btn-disabled nexttab btn-default gtm-onboard-4" onClick={()=> updateRequest()}>Save</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-sm-4 text-center">

                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div className="col-sm-4">
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )

}
export default AccountPage
